import React from 'react';
import { Link } from 'gatsby';
import styles from './Navigation.module.css';

const Navigation = () => (
  <nav role="navigation" className={styles.navigation}>
    <ul className={styles.list}>
      <li className={styles.item}>
        <Link to="/music/" className={styles.link}>Music</Link>
      </li>
      <li className={styles.item}>
        <Link to="/video/" className={styles.link}>Video</Link>
      </li>
      <li className={styles.item}>
        <Link to="/about/" className={styles.link}>About</Link>
      </li>
      <li className={styles.item}>
        <Link to="/press/" className={styles.link}>Press</Link>
      </li>
    </ul>
  </nav>
);

export default Navigation;
