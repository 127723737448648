import React from 'react';

import styles from './Background.module.css';

function Background({ imageUrl, children }) {
  return (
    <div
      className={styles.image}
      style={{
        backgroundImage: `url(${imageUrl})`,
      }}
    >
      {children}
    </div>
  );
}

export default Background;
