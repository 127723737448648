import React from 'react';
import Helmet from 'react-helmet';
import get from 'lodash/get';
import { useStaticQuery, graphql } from 'gatsby';
import Navigation from './Navigation';
import SocialLinks from './SocialLinks';
import Background from './Background';

import siteNameImg from '../assets/name.png';

import './normalize.css';
import './base.css';
import styles from './Layout.module.css';

function Layout({ children, isHomePage }) {
  const data = useStaticQuery(graphql`
    query LayoutQuery {
      site {
        siteMetadata {
          title
          description
        }
      }
      contentfulAsset(title: { eq: "background" }) {
        file {
          url
        }
      }
      allContentfulSocialLink(sort: { order: ASC, fields: order }) {
        edges {
          node {
            name
            order
            url
            icon {
              svg {
                content
              }
              file {
                url
              }
            }
          }
        }
      }
    }
  `);

  const bgImageUrl = get(data, 'contentfulAsset.file.url', '');
  const socialLinks = get(data, 'allContentfulSocialLink.edges', []).map(
    item => ({
      ...item.node,
      icon: {
        svg: get(item, 'node.icon.svg', null),
        file: get(item, 'node.icon.file', null),
      },
    })
  );

  return (
    <div className={styles.wrapper}>
      <Helmet title={data.site.siteMetadata.title}>
        <meta name="description" content={data.site.siteMetadata.description} />
        <link
          rel="apple-touch-icon"
          sizes="180x180"
          href="/apple-touch-icon.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="32x32"
          href="/favicon-32x32.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="16x16"
          href="/favicon-16x16.png"
        />
        <link rel="manifest" href="/site.webmanifest" />
      </Helmet>
      <Background imageUrl={bgImageUrl}>
        <div className={styles.siteName}>
          <img
            alt="All These Animals"
            src={siteNameImg}
            className={styles.siteNameImg}
          />
        </div>

        <Navigation />
      </Background>

      {!isHomePage && (
        <section className={styles.content}>
          {children}
          <footer className={styles.footer}>
            <small>copyright &copy; 2020 All These Animals</small>
          </footer>
        </section>
      )}

      <SocialLinks items={socialLinks} />
    </div>
  );
}

export default Layout;
