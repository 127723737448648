import React from 'react';

import styles from './SocialLinks.module.css';

function Icon({ svg, file, alt }) {
  if (svg && svg.content) {
    return <div dangerouslySetInnerHTML={{ __html: svg.content }} />;
  }

  return <img src={file.url} alt={alt} />;
}

const Item = ({ url, name, icon }) => (
  <li className={styles.item}>
    <a className={styles.icon} href={url} title={name}>
      <Icon alt={name} svg={icon.svg} file={icon.file} />
      <div className="visuallyhidden">{name}</div>
    </a>
  </li>
);

function SocialLinks({ items }) {
  return (
    <div className={styles.socialLinks}>
      <ul>
        {items.map(item => (
          <Item {...item} key={item.name} />
        ))}
      </ul>
    </div>
  );
}

export default SocialLinks;
